import "./MessageTile.css";
import dayjs from "dayjs";

const MessageTile = ({ messageData }) => {
  const isUserMessage = () => {
    return messageData.isUserMessage;
  };

  const getFormattedMessageTime = () => {
    return messageData?.sentDateTime
      ? dayjs(messageData.sentDateTime).format("HH:mm A")
      : "";
  };

  return (
    <div key={messageData.id} className={`message-tile-root`}>
      <div className={`message-details-container`}>
        <div
          className={`message-details ${
            isUserMessage() ? "my-message-tile" : ""
          }`}
        >
          <div className="message-text-container">{messageData.content}</div>
        </div>
        <div
          className={`time-details-container ${
            isUserMessage() ? "my-message-tile" : ""
          }`}
        >
          {!isUserMessage() ? (
            <span className="message-sender-name">
              {messageData.senderName}
            </span>
          ) : null}

          <span className="message-time">{getFormattedMessageTime()}</span>
        </div>
      </div>
    </div>
  );
};

export default MessageTile;
